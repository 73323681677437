export default {
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_REF_TOKEN(state, token) {
    state.refreshToken = token
  },
  SET_USER_INFO(state, item) {
    state.userInfo = item
  },
}
