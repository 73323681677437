import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
// 对应文件关系说明
// 1. 用户登录 login
// 2. 开户准备 accountPrepare
// 2.1 开户文件 accountFile
// 2.2 开户协议 accountAgreement
// 3. 客户声明 customerStatement
// 4. 身份认证 idAuth
// 4.1 上传身份证信息 uploadIdCard
// 4.2 上传持证照片 uploadPhoto
// 4.3 银行卡认证 bankCardAuth
// 5. 客户信息 customerInfo
// 5.1.个人信息 personalInformation
// 5.2.工作状况 workingConditions
// 5.3.银行资料 bankInformation
// 5.4.财务状况 financialStatus
// 5.5.投资经验 investmentExperience
// 5.6.税务信息 taxInformation
// 5.7.选择账户 selectAccount
// 6.风险评估 risk
// 6.1.风险评估问卷 assessmentQuestionnaire
// 6.2.评估结论 evaluationConclusion
// 7.客户确认 customerConfirmation
// 8.开户进度 accountProgress
const routes = [
  {
    path: '/',
    name: 'welcomePage',
    component: () => import('../views/user/welcomePage'),
    hidden: true,
  },
  {
    path: '/*',
    name: '404',
    component: () => import('../views/user/404'),
    hidden: true,
  },
  {
    path: '/gt',
    name: 'gt',
    redirect:'/gt/login',
    component: () => import('../components/GlTemplateLogin'),
    children: [
      {
        path: 'login',
        name: 'login',
        hidden: true,
        component: () => import('../views/user/login.vue'),
      },
      {
        path: 'accountFile',
        name: 'accountFile',
        component: () => import('../views/account/accountFile'),
        hidden: true,
      },
      {
        path: 'pdfShow1',
        name: 'pdfShow1',
        component: () => import('../views/account/pdf/pdfShow1'),
        hidden: true,
      },
      {
        path: 'pdfShow2',
        name: 'pdfShow2',
        component: () => import('../views/account/pdf/pdfShow2'),
        hidden: true,
      },
      {
        path: 'pdfShow3',
        name: 'pdfShow3',
        component: () => import('../views/account/pdf/pdfShow3'),
        hidden: true,
      },
      {
        path: 'pdfShow4',
        name: 'pdfShow4',
        component: () => import('../views/account/pdf/pdfShow4'),
        hidden: true,
      },
      {
        path: 'pdfShow5',
        name: 'pdfShow5',
        component: () => import('../views/account/pdf/pdfShow5'),
        hidden: true,
      },
]
  },
  {
    path: '/account',
    name: 'account',
    redirect:'/account/accountPrepare',
    component: () => import('../components/GlTemplate'),
    meta:{
      requiresAuth:true,
      name:'account'
    },
    children: [

      {
        path: 'accountPrepare',
        name: 'accountPrepare',
        component: () => import('../views/account/accountPrepare'),
        hidden: true,
      },

      {
        path: 'accountOpen',
        name: 'accountOpen',
        component: () => import('../views/account/accountOpen'),
        hidden: true,
      },
      {
        path: 'accountProgress1',
        name: 'accountProgress1',
        component: () => import('../views/account/accountProgress1'),
        hidden: true,
      },
      {
        path: 'accountProgress2',
        name: 'accountProgress2',
        component: () => import('../views/account/accountProgress2'),
        hidden: true,
      },
      {
        path: 'uploadFile',
        name: 'uploadFile',
        component: () => import('../views/account/uploadFile'),
        hidden: true,
      },
    ]
  },
  {
    path: '/idAuth',
    name: 'idAuth',
    redirect:"/idAuth/uploadIdCard",
    component: () => import('../components/GlTemplate'),
    meta:{
      requiresAuth:true,
      name:'idAuth'
    },
    children: [
      {
        path: 'uploadIdCard',
        name: 'uploadIdCard',
        component: () => import('../views/idAuth/uploadIdCard'),
        hidden: true,
      },
      {
        path: 'uploadPhoto',
        name: 'uploadPhoto',
        component: () => import('../views/idAuth/uploadPhoto'),
        hidden: true,
      },
      {
        path: 'bankCardAuth',
        name: 'bankCardAuth',
        component: () => import('../views/idAuth/bankCardAuth'),
        hidden: true,
      },
    ]
  },
  {
    path: '/customer',
    name: 'customer',
    redirect:"customer/customerStatement",
    component: () => import('../components/GlTemplate'),
    meta:{
      requiresAuth:true,
      name:'customer'
    },
    children: [
      {
        path: 'customerStatement',
        name: 'customerStatement',
        component: () => import('../views/customer/customerStatement'),
        hidden: true,
      },
      {
        path: 'personalInformation',
        name: 'personalInformation',
        component: () => import('../views/customer/personalInformation'),
        hidden: true,
      },
      {
        path: 'workingConditions',
        name: 'workingConditions',
        component: () => import('../views/customer/workingConditions'),
        hidden: true,
      },
      {
        path: 'bankInformation',
        name: 'bankInformation',
        component: () => import('../views/customer/bankInformation'),
        hidden: true,
      },
      {
        path: 'financialStatus',
        name: 'financialStatus',
        component: () => import('../views/customer/financialStatus'),
        hidden: true,
      },
      {
        path: 'investmentExperience1',
        name: 'investmentExperience1',
        component: () => import('../views/customer/investmentExperience1'),
        hidden: true,
      },
      {
        path: 'investmentExperience2',
        name: 'investmentExperience2',
        component: () => import('../views/customer/investmentExperience2'),
        hidden: true,
      },
      {
        path: 'investmentExperience3',
        name: 'investmentExperience3',
        component: () => import('../views/customer/investmentExperience3'),
        hidden: true,
      },
      {
        path: 'taxInformation',
        name: 'taxInformation',
        component: () => import('../views/customer/taxInformation'),
        hidden: true,
      },
      {
        path: 'selectAccount',
        name: 'selectAccount',
        component: () => import('../views/customer/selectAccount'),
        hidden: true,
      },
      {
        path: 'customerConfirmation',
        name: 'customerConfirmation',
        component: () => import('../views/customer/customerConfirmation'),
        hidden: true,
      },
      {
        path: 'againCustomerConfirmation',
        name: 'againCustomerConfirmation',
        component: () => import('../views/customer/againCustomerConfirmation'),
        hidden: true,
      },
    ]
  },
  {
    path: '/risk',
    name: 'risk',
    component: () => import('../components/GlTemplate'),
    redirect:"/risk/assessmentQuestionnaire",
    meta:{
      requiresAuth:true,
      name:'risk'
    },
    children: [
      {
        path: 'assessmentQuestionnaire',
        name: 'assessmentQuestionnaire',
        component: () => import('../views/risk/assessmentQuestionnaire'),
        hidden: true,
      },
      {
        path: 'evaluationConclusion',
        name: 'evaluationConclusion',
        component: () => import('../views/risk/evaluationConclusion'),
        hidden: true,
      },
    ]
  },
]

// 动态路由
export const routes2 = [
  {
    path: '/403',
    name: '403',
    component: () => import('../views/user/403'),
    hidden: true,
    meta:{
      requiresAuth:true, // 需要登录才能进入
    }
  },
]
const router = createRouter({

  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),

  routes
})

export default router
