<template>
<!--  禁止手机输入框放大  -->
  <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no">

  <!-- 限制界面为竖屏，旋转屏幕也不会改变当前状态 -->
  <meta name="screen-orientation" content="portrait"/>
<!--  pc-->
  <van-row  v-if="pcTag" >
    <van-col span="8"  offset="8"  style="min-height: 100vh; padding-bottom: 10%;position: relative">
      <router-view />
      <div style="">
        <div style="position: absolute;bottom: 0;text-align: center;width: 100%;">
          <p>24小时客服热线 <span style="color: rgb(36, 100, 240);">400-120-9003</span></p>
          <p>©2021 冠力金融服務有限公司版权所有</p>
        </div>
      </div>
    </van-col>
  </van-row>

<!--phone-->
  <van-row  v-if="!pcTag" >
    <van-col span="24" style="min-height: 100vh;padding-bottom: 10%;position:relative;">
      <router-view style="padding-bottom: 10%" />
      <div style="position:absolute;bottom: 0;width: 100%;text-align: center;margin-top: 10%">
        <div>
          <p>24小时客服热线 <span style="color: rgb(36, 100, 240);">400-120-9003</span></p>
          <p>©2021 冠力金融服務有限公司版权所有</p>
        </div>
      </div>
    </van-col>
  </van-row>
</template>

<!--//在 src / app.vue 中 script 标签内添加代码 禁止手机屏幕放大缩小-->
<script>
import "@/assets/css/allStyle.css";

export default {
  name:'app',
  data(){
    return{
      pcTag:false
    }
  },
  created () {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    this.pcTag = !flag;
    // 禁止用户放大缩小
    window.onload = function() {
      document.addEventListener('touchstart', function(event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      })
      document.addEventListener('gesturestart', function(event) {
        event.preventDefault()
      })
    }
  }

}


</script>
<style>

</style>
