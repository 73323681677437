import router from '../router'
import {getToken} from "@/utils/auth";

const pathArr = ["/gt/login", "/","/gt/accountFile","/gt/accountFile","/gt/pdfShow1","/gt/pdfShow2","/gt/pdfShow3","/gt/pdfShow4"]

router.beforeEach((to,from,next)=>{
  if (getToken()){
    // 获取到token 已经登录
    next()
  }else {
    // 用户未登录, 判断权限是否需要登录,
    if (pathArr.indexOf(to.path)>=0){
    // if (to.path === "/gt/login" || to.path === "/" || to.path === "/gt/accountFile"){
      next()
    }else {
        next("/gt/login")
    }
  }
})


