import {createApp} from 'vue'
import App from './App.vue'
import router from './router/index'
import './router/permission'
import store from './store'
import 'vant/es/toast/style';


import {
  Button,
  CellGroup,
  Checkbox,
  Col,
  ConfigProvider,
  Field,
  Form,
  Image,
  Loading,
  NavBar,
  Overlay,
  Radio,
  RadioGroup,
  Row,
  Uploader,
  CheckboxGroup,
  DatePicker,
  Dialog,
  Toast,
  Space,
  Picker,
  Step,
  Steps,
  Icon
} from 'vant';
import 'vant/lib/index.css';

import vueEsign from 'vue-esign'
const app = createApp(App);
app.use(vueEsign)
app.use(Step );
app.use(Steps  );
app.use(Dialog  );
app.use(Toast );
app.use(DatePicker);
app.use(Uploader);
app.use(Icon);
app.use(Radio);
app.use(RadioGroup);
app.use(Space);
app.use(Form);
app.use(CellGroup);
app.use(router);
app.use(NavBar);
app.use(Field)
app.use(Image)
app.use(Button)
app.use(Col)
app.use(Row)
app.use(Loading)
app.use(ConfigProvider)
app.use(Overlay)
app.use(Checkbox)
app.use(CheckboxGroup)
app.use(Picker)

app.use(store).mount('#app');
