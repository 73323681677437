const TokenKey = 'glaos'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
   localStorage.setItem(TokenKey,token)
}

export function removeToken() {
  localStorage.removeItem('store')
  localStorage.removeItem(TokenKey)
}



