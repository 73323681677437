import {createStore} from 'vuex'
import state from './modules/state'
import getters from './modules/getters'
import mutations from './modules/mutations'
import VuexPersistence from "vuex-persist"

const vueLocal=new VuexPersistence({
  // storage 存储方式  默认的是localStorage存储，可以修改成会话存储（window.sessionStorage）
  storage:window.localStorage
})

// 参考网址: https://blog.csdn.net/qq_41520636/article/details/121882632?utm_medium=distribute.pc_relevant.none-task-blog-2~default~baidujs_baidulandingword~default-0-121882632-blog-114641297.pc_relevant_3mothn_strategy_and_data_recovery&spm=1001.2101.3001.4242.1&utm_relevant_index=3
// store的执行顺序：
// 打开浏览器 → getters → 组件调用actions中的方法 → mutations（设置state的值） → getters（更新数据）
export default createStore({
  // 全局参数
  state: state,
  // get方法, 并且每次打开浏览器优先执行该方法，获取所有的状态
  getters: getters,
  // set方法
  mutations: mutations,
  // 处理state的方法体,
  actions: {},
  //
  modules: {},
  plugins: [vueLocal.plugin]
})
